@import url("/extensions/fontawesome.css");
@font-face {
  font-family: Oswald;
  src: url("../webfonts/Oswald-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Oswald;
  src: url("../webfonts/Oswald-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Oswald;
  src: url("../webfonts/Oswald-Bold.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Orienta;
  src: url("../webfonts/Orienta-Regular.ttf");
  font-display: swap;
}
